@use '@angular/material' as mat;
// @use 'color' as oulds;
@use 'oulds.palette' as oulds;
@import '@material/density/variables';

@include mat.core();

$app-primary-palette: (
	50: map-get(oulds.$palette-light, primary),
	100: map-get(oulds.$palette-light, primary),
	200: map-get(oulds.$palette-light, primary),
	300: map-get(oulds.$palette-light, primary),
	400: map-get(oulds.$palette-light, primary),
	500: map-get(oulds.$palette-light, primary),
	600: map-get(oulds.$palette-light, primary),
	700: map-get(oulds.$palette-light, primary),
	800: map-get(oulds.$palette-light, primary),
	900: map-get(oulds.$palette-light, primary),
	A100: map-get(oulds.$palette-light, primary),
	A200: map-get(oulds.$palette-light, primary),
	A400: map-get(oulds.$palette-light, primary),
	A700: map-get(oulds.$palette-light, primary),
	contrast: (
		50: map-get(oulds.$palette-light, primary-contrast),
		100: map-get(oulds.$palette-light, primary-contrast),
		200: map-get(oulds.$palette-light, primary-contrast),
		300: map-get(oulds.$palette-light, primary-contrast),
		400: map-get(oulds.$palette-light, primary-contrast),
		500: map-get(oulds.$palette-light, primary-contrast),
		600: map-get(oulds.$palette-light, primary-contrast),
		700: map-get(oulds.$palette-light, primary-contrast),
		800: map-get(oulds.$palette-light, primary-contrast),
		900: map-get(oulds.$palette-light, primary-contrast),
		A100: map-get(oulds.$palette-light, primary-contrast),
		A200: map-get(oulds.$palette-light, primary-contrast),
		A400: map-get(oulds.$palette-light, primary-contrast),
		A700: map-get(oulds.$palette-light, primary-contrast),
	),
);

$app-accent-palette: (
	50: map-get(oulds.$palette-light, accent),
	100: map-get(oulds.$palette-light, accent),
	200: map-get(oulds.$palette-light, accent),
	300: map-get(oulds.$palette-light, accent),
	400: map-get(oulds.$palette-light, accent),
	500: map-get(oulds.$palette-light, accent),
	600: map-get(oulds.$palette-light, accent),
	700: map-get(oulds.$palette-light, accent),
	800: map-get(oulds.$palette-light, accent),
	900: map-get(oulds.$palette-light, accent),
	A100: map-get(oulds.$palette-light, accent),
	A200: map-get(oulds.$palette-light, accent),
	A400: map-get(oulds.$palette-light, accent),
	A700: map-get(oulds.$palette-light, accent),
	contrast: (
		50: map-get(oulds.$palette-light, accent-contrast),
		100: map-get(oulds.$palette-light, accent-contrast),
		200: map-get(oulds.$palette-light, accent-contrast),
		300: map-get(oulds.$palette-light, accent-contrast),
		400: map-get(oulds.$palette-light, accent-contrast),
		500: map-get(oulds.$palette-light, accent-contrast),
		600: map-get(oulds.$palette-light, accent-contrast),
		700: map-get(oulds.$palette-light, accent-contrast),
		800: map-get(oulds.$palette-light, accent-contrast),
		900: map-get(oulds.$palette-light, accent-contrast),
		A100: map-get(oulds.$palette-light, accent-contrast),
		A200: map-get(oulds.$palette-light, accent-contrast),
		A400: map-get(oulds.$palette-light, accent-contrast),
		A700: map-get(oulds.$palette-light, accent-contrast),
	),
);

$app-warn-palette: (
	50: map-get(oulds.$palette-light, error),
	100: map-get(oulds.$palette-light, error),
	200: map-get(oulds.$palette-light, error),
	300: map-get(oulds.$palette-light, error),
	400: map-get(oulds.$palette-light, error),
	500: map-get(oulds.$palette-light, error),
	600: map-get(oulds.$palette-light, error),
	700: map-get(oulds.$palette-light, error),
	800: map-get(oulds.$palette-light, error),
	900: map-get(oulds.$palette-light, error),
	A100: map-get(oulds.$palette-light, error),
	A200: map-get(oulds.$palette-light, error),
	A400: map-get(oulds.$palette-light, error),
	A700: map-get(oulds.$palette-light, error),
	contrast: (
		50: map-get(oulds.$palette-light, error-contrast),
		100: map-get(oulds.$palette-light, error-contrast),
		200: map-get(oulds.$palette-light, error-contrast),
		300: map-get(oulds.$palette-light, error-contrast),
		400: map-get(oulds.$palette-light, error-contrast),
		500: map-get(oulds.$palette-light, error-contrast),
		600: map-get(oulds.$palette-light, error-contrast),
		700: map-get(oulds.$palette-light, error-contrast),
		800: map-get(oulds.$palette-light, error-contrast),
		900: map-get(oulds.$palette-light, error-contrast),
		A100: map-get(oulds.$palette-light, error-contrast),
		A200: map-get(oulds.$palette-light, error-contrast),
		A400: map-get(oulds.$palette-light, error-contrast),
		A700: map-get(oulds.$palette-light, error-contrast),
	),
);

// palette, default, lighter, darker, text
$app-primary: mat.define-palette($app-primary-palette);
$app-accent: mat.define-palette($app-accent-palette);
$app-warn: mat.define-palette($app-warn-palette);

// output cssvars for oulds.$palette-light
:root {
	--mat-toolbar-container-background-color: #fff;
	--mat-toolbar-container-text-color: #c02032;
	--mat-table-header-container-height: 48px;
	--mat-table-row-item-container-height: 42px;
	--mat-table-row-item-outline-color: #babfc7;
	background-color: var(--color-grey-9);
	@include mat.form-field-density(-2);
	@include mat.radio-density(-2);
	@each $key, $value in oulds.$palette-light {
		--color-#{$key}: #{$value};
	}
}

$light-theme: mat.define-light-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-accent,
			warn: $app-warn,
		),
		typography:
			mat.define-typography-config(
				'"Open Sans", "Roboto", "Helvetica Neue", sans-serif'
			),
	)
);

// @include mat.angular-material-theme($light-theme);

@include mat.core-theme($light-theme);

@include mat.all-component-bases($light-theme);
@include mat.all-component-colors($light-theme);
@include mat.all-component-typographies($light-theme);
@include mat.all-component-densities($light-theme);

.mat-mdc-row:nth-child(even) {
	background-color: var(--color-grey-10) !important;
}
