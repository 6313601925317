html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
}

.material-icons-outlined {
	font-variation-settings:
		'FILL' 0,
		'wght' 100,
		'GRAD' 0,
		'opsz' 48;
}

@import 'themes/oulds/oulds.theme';
